import React from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {useCanonical} from "../../hooks/useCanonical";

const Seo = ({page}) => {
    const location = useLocation();

    const canonicalUrl = useCanonical(location.pathname)

    console.log('canonical', canonicalUrl)

    return (
        <Helmet>
            <title>{page.meta_title ? page.meta_title : page.title}</title>
            <link rel="canonical" href={canonicalUrl}/>
            <link rel="preload" fetchpriority="high" as="image"
                  href={process.env.REACT_APP_STATIC_URL + 'img/news.webp'} type="image/webp"/>
            <meta name="description" content={page.meta_description ? page.meta_description : page.subtext}/>
            <meta name="keywords" content={page.meta_keywords}/>
            <meta property="og:type" content="website"/>
            <meta property='og:title' content={page.meta_title ? page.meta_title : page.title}/>
            <meta property='og:image'
                  content={page.meta_img ? process.env.REACT_APP_STATIC_URL + page.meta_img : process.env.REACT_APP_STATIC_URL + page.img}/>
            <meta property='og:description' content={page.meta_description ? page.meta_description : page.subtext}/>
            <meta property='og:url' content={window.location.href}/>

            <meta property="twitter:site" content="website"/>
            <meta property="twitter:url" content={window.location.href}/>
            <meta property="twitter:title" content={page.meta_title ? page.meta_title : page.title}/>
            <meta property="twitter:description" content={page.meta_description ? page.meta_description : page.subtext}/>
            <meta property="twitter:image" content={page.meta_img ? process.env.REACT_APP_STATIC_URL + page.meta_img : process.env.REACT_APP_STATIC_URL + page.img}/>
        </Helmet>
    );
};

export default Seo;