import React from 'react';
import classes from './menu.module.sass';
import {useLocation} from "react-router-dom";
import Space from "../space/space";
import CustomLink from "../customLink/customLink";
import CustomImg from "../customImg/customImg";
import T from "../../components/t/t";

const Menu = ({translate, menu, active, children, onClick, onClose, currentLang, datas}) => {
    const location = useLocation();

    return (
        <>
            <div className={classes.menu + ' ' + classes.desktop}>
                {
                    menu.sort((a, b) => a.position - b.position).map(item => {
                        return (
                            <CustomLink
                                key={item.id}
                                to={'/' + item.url}
                                alt={item.topic}
                                className={
                                    location.pathname === '/' + item.url || location.pathname === `/${currentLang}/${item.url}`
                                        ? classes.menuItem + ' ' + classes.current
                                        : classes.menuItem
                                }
                            >
                                {item.topic}
                            </CustomLink>
                        );
                    })
                }
            </div>
            <div className={classes.menu + ' ' + classes.mobile}>
                <div className={classes.menuHeader} onClick={onClick}>
                    <p className={classes.title}><T datas={datas}>Menu</T></p>
                    <CustomImg width={36} height={36} src={process.env.PUBLIC_URL + '/icon/burger.svg'} alt={"wheelie.lv menu"} />
                </div>
                <div className={active ? classes.menuItems + ' ' + classes.active : classes.menuItems}>
                    <div onClick={onClose} className={classes.menuClose}></div>
                    <div className={classes.menuItem}>
                        <div className={classes.menuItemClose}>
                            {translate}
                            <CustomImg
                                width={36} height={36} onClick={onClose}
                                src={process.env.PUBLIC_URL + '/icon/close.svg?as=webp'} alt={"wheelie.lv close menu"}
                            />

                        </div>
                        <Space space={1}/>
                        {
                            menu.sort((a, b) => a.position - b.position).map(item =>
                                !item.toScroll  &&
                                    <CustomLink
                                        className={location.pathname === '/' + item.url ? classes.menuItemLink + ' ' + classes.current : classes.menuItemLink}
                                        style={(location.pathname.split('/', 3).length > 2 && location.pathname === item.url) ? {textDecoration: 'underline'} : {textDecoration: 'none'}}
                                        key={item.id}
                                        to={'/' + item.url}
                                        alt={item.topic}>
                                        {item.topic}
                                    </CustomLink>
                            )
                        }
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Menu;