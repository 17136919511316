import {$host} from "../http/axios";

export default class SiteMethods {
    static getLangs = async () => {
        const {data} = await $host.post(`/lang`);
        return data;
    }

    static getConfig = async (lang) => {
        const {data} = await $host.post(`/config/${lang}`);
        return data;
    }

    static getCategories = async (lang) => {
        const {data} = await $host.post(`/categories/${lang}`);
        return data;
    }

    static getMenu= async (lang) => {
        const {data} = await $host.post(`/menu/${lang}`);
        return data;
    }

    static getMain = async (lang) => {
        const {data} = await $host.post(`/main/${lang}`);
        console.log('dataMenu', data)
        return data;
    }

    static getTypeTrailers = async (lang) => {
        const {data} = await $host.post(`/types/${lang}`, {minimum_license: "B"});
        return data;
    }

    static getTrailers = async (lang) => {
        const {data} = await $host.post(`/trailers/${lang}`);
        return data;
    }

    static getPartners = async (lang) => {
        const {data} = await $host.post(`/partners/${lang}`);
        return data;
    }

    static getNews = async (lang) => {
        const {data} = await $host.post(`/news/${lang}`);
        return data;
    }

    static getShared = async (lang) => {
        const {data} = await $host.post(`/shared/${lang}`);
        return data;
    }
    //
    static getSocial = async (lang) => {
        const {data} = await $host.post(`/social/${lang}`);
        return data;
    }

    static getFaq = async (lang) => {
        const {data} = await $host.post(`faq/${lang}`);
        return data;
    }

    static addStatic = async (formData) => {
        const {data} = await $host.post(`/staticAdd`, formData);
        return data;
    }

    static getStatic = async (lang, type) => {
        const {data} = await $host.post(`/static/${lang}/${type}`);
        return data;
    }

    static getParking = async (lang) => {
        const {data} = await $host.post(`/parking/${lang}`);
        return data;
    }
}