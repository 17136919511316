import React from 'react';
import Container from "../../ui/container/container";
import Back from "../../ui/back/back";
import ColumnsSmall from "../../ui/columns/columnsSmall";
import parse from "html-react-parser";
import T from "../t/t";

const TextComponent = ({datas, sub}) => {

    return (
        <Container>
            <ColumnsSmall>
                <Back to={'/'} title={<T datas={datas}>Back to main</T>}/>
                {
                    sub !== 'tarifi' ?
                        <>
                            <h1 style={{textAlign: 'left'}}>{datas[sub].topic}</h1>
                            {
                                datas[sub].text && parse(datas[sub].text)
                            }
                        </>

                        :
                        <>
                            <h1 style={{textAlign: 'left'}}>{Object.values(datas.main).filter(a => a.url === sub)[0].topic}</h1>
                            {
                                Object.values(datas.main).filter(a => a.url === sub)[0].text && parse(Object.values(datas.main).filter(a => a.url === sub)[0].text)
                            }
                        </>

                }

            </ColumnsSmall>
        </Container>
    );
};

export default TextComponent;