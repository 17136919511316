import React from 'react';
import classes from './checkbox.module.sass';

const Checkbox = ({checked, onChange, disabled, children, htmlFor}) => {
    const [active, setActive] =  React.useState(checked);

    const toggleCheckbox = () => {
        setActive(() => !active);
    }

    return (
        <div className={classes.checkbox}>
            <input id={htmlFor} type="checkbox" onChange={onChange} disabled={disabled} checked={!active} />
            <label
                onClick={!disabled ? toggleCheckbox : null}
                htmlFor={htmlFor}
                className={
                    active
                        ?
                            disabled ? classes.checkbox__label + ' ' + classes.desibled : classes.checkbox__label
                        :
                            classes.checkbox__label + ' ' + classes.checkbox__label__uncheked
                }
            >
                {children}
            </label>
        </div>
    );
};

export default Checkbox;