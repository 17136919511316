import React from "react";

const useCanonical = (url) => {
    const [canonicalUrl, setCanonicalUrl] = React.useState('');

    React.useEffect(() => {
        if (url.split('/')[1] === 'ru' || url.split('/')[1] === 'en') {
            const protocol = window.location.href.split('//')[0]
            const lang = window.location.href.split('//')[1].split('/')[0]
            const address = window.location.href.split('//')[1].split('/')
            if (address.length === 2) {
                setCanonicalUrl(() => protocol + '//' + address[0])
            } else if (address.length === 3) {
                setCanonicalUrl(() => protocol + '//' + address[0] + '/' + address[2])
            } else if (address.length === 4) {
                setCanonicalUrl(() => protocol + '//' + address[0] + '/' + address[2] + '/' + address[3])
            }
        } else {
            setCanonicalUrl(() => window.location.href)
        }
    }, [url]);

    return canonicalUrl;
}

export {
    useCanonical,
}