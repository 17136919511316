import axios from "axios";

const headers = {
    'Content-Security-Policy': 'https://wheelie.lv',
    'Content-Type': 'multipart/form-data'
}

const $host_bot = axios.create({
    baseURL: process.env.REACT_APP_HOST_BOT,
});

const $host = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers
});

export {
    $host,
    $host_bot
};