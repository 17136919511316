import React from 'react';
import classes from './footer.module.sass';
import Container from "../../ui/container/container";
import Space from "../../ui/space/space";
import Contacts from "../../ui/contacts/contacts";
import Columns from "../../ui/columns/columns";
import Form from "../../ui/form/form";
import Input from "../../ui/input/input";
import Button from "../../ui/button/button";
import {useLocation} from "react-router-dom";
import Checkbox from "../../ui/checkbox/checkbox";
import CustomLink from "../../ui/customLink/customLink";
import CustomImg from "../../ui/customImg/customImg";
import T from "../t/t";
import {$host_bot} from "../../http/axios";

const FooterComponent = ({lang, menu, datas, pageC}) => {
    const contacts = [
        {id: 1, icon: process.env.PUBLIC_URL + '/icon/mailLite.svg', text: 'info@wheelie.lv', type: 'mailto'},
        {id: 2, icon: process.env.PUBLIC_URL + '/icon/phoneLite.svg', text: '+37120023677', type: 'tel'}, //+371 2004-5909
        {id: 3, icon: process.env.PUBLIC_URL + '/icon/locationLite.svg', text: <T datas={datas}>Babītes novads, Babītes pagasts, Piņķi, Priedaines iela 19, LV-2107, Latvija</T>, type: 'location'}
    ]

    const location = useLocation();
    const [valid, setValid] = React.useState(false);

    const [email, setEmail]  = React.useState('');
    const [phone, setPhone]   = React.useState('');
    const [name, setName]  = React.useState('');
    const [message, setMessage] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [termsPolicy, setTermsPolicy] = React.useState([]);
    const [good, setGood] = React.useState(false)


    const [page, setPage] = React.useState({});

    React.useEffect(() => {
        setPage(() => menu.filter(item => item.types === "contacts")[0])
    }, [menu])

    console.log('pp', page);

    React.useEffect(() => {
        setTimeout(()  =>  {
            if (email.length  &&  name.length  &&  message.length && phone.length && checked) {
                setValid(() => true);
            } else  {
                setValid(() => false);
            }
        }, 300);

    }, [email, name, message, phone, checked])

    const toggleCheckbox = () => {
        setChecked(() => !checked);
    }

    React.useEffect(() => {
        setTermsPolicy(() => [datas.menu.terms, datas.menu.policy])
    }, [location.pathname, datas.menu.terms, datas.menu.policy]);

    const textOnBot = `
            Сообщение с сайта wheelie.lv
            ---------
            ФИО: ${name}
            Email: ${email}
            Телефон: ${phone}
            Сообщение: ${message}
            ---------
            `

    const sendTG = () => {
        let formData = new FormData();
        formData.append('text', textOnBot);
        formData.append('apiKey', process.env.REACT_APP_TG_API_KEY);

        $host_bot.post(`/sendBot`, formData)
            .then(() => {
                setName('');
                setPhone('');
                setEmail('');
                setMessage('');
                setGood(true)
            })
            .catch(
                err => console.log(err)
            )
    }

    return (
        <section className={classes.footer} id={'contacts'}>
            {
                good
            }
            <Container>
                <div className={classes.header}>
                    {
                        pageC
                            ?
                            <h1>{page?.topic}</h1>
                            :
                            <h2>{page?.topic}</h2>
                    }

                    <div className={classes.header__text}>
                        <p><T datas={datas}>You can contact us through email, by phone or by filling out the contact form. We will get
                            back to you as soon as possible.</T></p>
                    </div>
                </div>
                <Space space={2}/>
                <Columns
                    cta={
                        <>
                            <Contacts
                                data={contacts}
                            />
                            <Space space={1}/>
                        </>
                    }
                >
                    <Form>
                        <Input htmlFor={'name'} label={<T datas={datas}>Your name</T>} value={name} onChange={e => setName(e.target.value)}/>
                        <Input htmlFor={'email'} label={<T datas={datas}>Email</T>} value={email} onChange={e => setEmail(e.target.value)}/>
                        <Input htmlFor={'phone'} label={<T datas={datas}>Phone number</T>} value={phone} onChange={e => setPhone(e.target.value)}/>
                        <Input htmlFor={'message'} label={<T datas={datas}>Message</T>} value={message} onChange={e => setMessage(e.target.value)}/>
                        <Checkbox
                            checked={checked}
                            onChange={toggleCheckbox}
                            htmlFor={'form'}
                        >
                            <p><T datas={datas}>I consent to processing of my personal data according to Wheelie</T>&nbsp;
                                {
                                    termsPolicy.length && <CustomLink to={'/' + termsPolicy[1]?.link}>{termsPolicy[1]?.topic}</CustomLink>
                                }
                            </p>
                        </Checkbox>
                        <Space space={2}/>
                        <Button disabled={!valid} onClick={sendTG}><T datas={datas}>Отправить</T></Button>
                    </Form>
                </Columns>
                <Space space={4}/>
                <div className={classes.footer__block}>
                    <div className={classes.footer__Logo}>
                        <CustomLink to="/" width={128} height={36}>
                            <CustomImg
                                width={128}
                                height={36}
                                src={process.env.PUBLIC_URL + '/icon/logoWhite.svg'}
                                alt="logo"
                            />
                        </CustomLink>
                    </div>
                    <div className={classes.footer__menu}>
                        <p><span><T datas={datas}>Navigacija</T></span></p>
                        <div className={classes.footer__menu_links}>
                            {
                                menu.map(item =>
                                    <CustomLink
                                        key={item.id}
                                        to={`/${item.url}`}
                                        alt={item.topic}
                                        className={
                                            location.pathname === '/' + item.url || location.pathname === `/${lang}/${item.url}` ?
                                                classes.current : ''
                                    }
                                    >
                                        {item.topic}
                                    </CustomLink>
                                )
                            }
                        </div>
                    </div>
                    <div className={classes.footer__social}>
                        <p><span><T datas={datas}>Socials</T></span></p>
                        <div className={classes.footer__social_icons}>
                            {
                                Object.values(datas['social']).map(item =>
                                    <CustomLink key={item.id} to={item.url} target={'_blank'} alt={item.topic}>
                                        <CustomImg
                                            width={36} height={36}
                                            src={process.env.REACT_APP_STATIC_URL + item.img}
                                            alt={item.topic}
                                        />
                                    </CustomLink>
                                )
                            }
                        </div>
                    </div>
                    <div className={classes.footer__contacts}>
                        <h3><T datas={datas}>SIA SJV</T></h3>
                        <p><T datas={datas}>Reg. nr.: 40103057101</T></p>
                        <p><span><T datas={datas}>Juridiska adrese</T></span></p>
                        <p><T datas={datas}>Babītes novads, Babītes pagasts, Piņķi, Priedaines iela 19, LV-2107, Latvija</T></p>
                    </div>
                </div>
                <div className={classes.footer__copiright}>
                    <div className={classes.footer__copiright_text}>
                        <p>{new Date().getFullYear()} © wheelie.lv</p>
                    </div>
                    <div className={classes.footer__right}>
                        {
                            termsPolicy.map(item => {
                                return (
                                    <CustomLink key={item?.id} to={`/${item?.link}`}>{item?.topic}</CustomLink>
                                )
                            })
                        }
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default FooterComponent;